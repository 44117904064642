<template>
  <v-card color="transparent" tile elevation="0">
    <v-row justify="center" class="my-8" no-gutters align="center" style="min-height: 350px">
      <v-col cols="11" lg="9">
        <div class="text-center">
          <p class="text-body-1">{{ $t(`label.processingYourOrder`) }}</p>
          <p class="text-body-1">{{ $t(`label.pleaseDontRefreshPage`) }}</p>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { ORDER_UPDATE_STATUS } from '@/store/order.module'
import { SESSION, SHARED } from '@/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
export default {
  data: () => ({
    shared: SHARED,
    paymentInfo: {}
  }),
  computed: {
    order() {
      return this.$store.state.order.order
    }
  },
  async created() {
    await this.cancelOrder()
  },
  methods: {
    async cancelOrder() {
      this.paymentInfo = JSON.parse(localStorage.getItem(SESSION.PAYMENT_TRANSACTION_INFO))
      localStorage.removeItem(SESSION.PAYMENT_TRANSACTION_INFO)

      let data = {
        isSendEmail: false,
        isOrderSealed: false,
        orderUuid: this.paymentInfo.orderUuid,
        action: 'cancelOrder'
      }
      await this.$store.dispatch(ORDER_UPDATE_STATUS, { data })
      this.$router.push({
        name: ROUTE_NAME.PAYMENT_COMPLETE_CANCEL
      })
    }
  }
}
</script>

<style lang="scss">
.orderHistory-box {
  border: 1px solid #d5dde0;
}
.history-table-left-info {
  width: 180px;
}

.history-product-image-box {
  border: 1px solid var(--v-light_gray-base);
}
</style>
